<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-cloud-download
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card
      no-body
    >
      <b-card-body>
        <div class="d-flex justify-content-between  flex-wrap">
          <b-form-group
            class="mr-1 mb-md-0 d-flex"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="gradient-primary"
              block
              :to="{ name: 'news-create' }"
            >
              เพิ่มข่าวประชาสัมพันธ์
            </b-button>
          </b-form-group>
        </div>
      </b-card-body>
      <b-table
        striped
        hover
        responsive
        show-empty
        class="position-relative items-center"
        :per-page="perPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + (data.index + 1) }}
        </template>
        <template #empty="scope">
          <div class="p-2 text-center">
            <img
              src="/box.png"
              alt="empty"
              height="40px"
            > {{ scope.emptyFiltered ? 'No matching records found' : 'ไม่พบข้อมูล' }}
          </div>
        </template>
        <template #cell(picture)="data">
          <b-avatar
            size="lg"
            :src="data.item.picture"
          />
        </template>
        <template #cell(ip)="data">
          <div class="text-nowrap">
            <a
              :href="`http://${data.item.ip}`"
              target="_blank"
            >{{ data.item.ip }}</a>

          </div>
        </template>

        <template #cell(imgshow)="data">
          <img
            :src="data.item.img_url"
            alt="img"
            class="img-fluid"
            style="width: 100px; height: 100px"
          >
        </template>

        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-button
              v-b-tooltip.hover.v-info
              title="รายละเอียด"
              variant="gradient-info"
              size="sm"
              :to="{ path: '/News/edit/' + data.item._id }"
            >
              <i class="fas fa-pencil" />
            </b-button>
          </div>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
            @change="getUsers()"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @input="getUsers()"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BTable, BAvatar, BFormGroup, BFormSelect,
  BPagination, BButton, BCardBody, VBToggle, BOverlay,
  BIconCloudDownload, VBTooltip,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment-timezone'
// import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BButton,
    BCardBody,
    BOverlay,
    BIconCloudDownload,
    // BFormCheckbox,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      fullName: '',
      mobile: '',
      remark: '',
      search_type_option: [
        { value: 1, text: 'ยูสเซอร์เนม' },
        { value: 2, text: 'เบอร์โทร' },
        { value: 3, text: 'ชื่อลูกค้า' },
        { value: 4, text: 'เลขบัญชี' },
      ],
      search_type: null,
      search_val: null,
      show: true,
      prolist: [],
      pro_id: null,
      depositdata: [],
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      banklist: [],
      update_id: null,
      edit_id: null,
      edit_password: null,
      edit_name: null,
      edit_surname: null,
      edit_bank: null,
      edit_acc_no: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'index', label: 'ลำดับ' },
        { key: 'imgshow', label: 'รูปภาพ' },
        { key: 'title', label: 'หัวข่าว' },
        { key: 'created_at', label: 'ลงทะเบียนเมื่อ', formatter: value => (value ? moment(value).tz('Asia/Bangkok').add('hours', 7).format('DD/MM/YYYY HH:mm:ss') : '') },
        { key: 'actions', label: 'จัดการ' },
      ],
      /* eslint-disable global-require */
      items: [],
      addNewDataSidebar: false,
      UserData: JSON.parse(localStorage.getItem('userData')),
      dateStart: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
      dateEnd: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    this.getUsers()
  },
  methods: {
    editmember(data) {
      this.edit_id = data.id
      this.edit_name = data.name
      this.edit_surname = data.surname
      this.edit_bank = data.bank
      this.edit_acc_no = data.acc_no
      // console.log(data)
    },
    update_member() {
      const params = {
        name: this.edit_name,
        password: this.edit_password,
        surname: this.edit_surname,
        bank: this.edit_bank,
        acc_no: this.edit_acc_no,
      }
      // console.log(params)
      this.$http
        .post(`/users/update_namepass/${this.edit_id}`, params)
        .then(response => {
          console.log(response)
          this.show = false
          this.getUsers()
          this.edit_name = ''
          this.edit_password = ''
          this.edit_surname = ''
          this.edit_bank = ''
          this.edit_acc_no = ''
        })
        .catch(error => console.log(error))
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    async getUsers() {
      this.show = true
      // const index = 0
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
      }
      await this.$http
        .get('/news/list', { params })
        .then(response => {
          this.show = false
          this.onFiltered(response.data)
        })
        .catch(error => console.log(error))
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems.data
      this.totalRows = filteredItems.total
      // this.currentPage = 1
    },
    addData() {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.toggleDataSidebar(true)
      this.datasidebar = {}
    },
    editData(data) {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.toggleDataSidebar(true)
      this.datasidebar = data
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

  <style lang="scss" scoped>
  @import "~@core/scss/base/pages/app-invoice.scss";
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  .form-item-section {
  background-color:$product-details-bg;
  }
  </style>
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
  </style>
